body {
  margin: 0;
  font-family: "Open Sans", Arial, Verdana, Tahoma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.wrapper {
  height: 500px !important;
}
.form-check-inline {
  font-size: .8rem;
}

.home-content {
  text-align: left;
  font: normal "Open Sans";
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  max-width: 1140px;
}

code {
  font-family: 'open sans',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chart-option-label {
  text-align: left;
  font: "Open Sans";
  letter-spacing: 0px;
  color: #2B333F;
  opacity: 1;
}

.daily-files {
  text-align: center;
  font: "Open Sans";
  letter-spacing: 0px;
  color: #000000;
}

.large-spinner {
  width: 3rem;
  height: 3rem;
}



.hero .hero_image {

  background: transparent url("./img/root_server.jpg") 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 300px;
  width: 100%;
  padding-top: 110px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

}


.one_col,
.hero_overlay,
.navtabs ul,
.footer ul {
  max-width: 1128px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.hero_overlay h1 {
  font-size: 40px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  margin: 0px;
  color: white;
  text-align: center;

}



.clear {
  clear: both;
}

a {
  text-decoration: none;
}

.top .logo img {
  display: block;
  float: left;
  margin: 18px 0px 18px 15px;
  width: 160px;
  height: 55px;
}


.hero .hero_image {
  background-image: url("./img/root_server.jpg");
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  padding-top: 110px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

}


.one_col,
.hero_overlay,
.navtabs ul,
.footer ul {
  max-width: 1128px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.hero_overlay h1 {
  font-size: 40px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  margin: 0px;
  color: white;
  text-align: center;
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', Arial, Verdana, Tahoma, sans-serif;

}

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
  padding-top: 4rem !important;
}
.navtabs,
.navtabs a {
  background-color: #46545D;
  background-image: -webkit-linear-gradient(top, #46545D, #3A464D);
  background-image: linear-gradient(to bottom, #46545D, #3A464D);
}

.navtabs ul {
  list-style-type: none;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}


.navtabs li {
  width: 33%;
  display: block;
  float: left;
  margin: 0px;
  padding: 0px;
}

.navtabs a {
  display: block;
  padding: 14px 8px;
  text-align: center;
  font-family: "Open Sans","Lucida Sans Unicode", "Lucida Grande", Arial, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: normal;
  color: white;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  border: 0px;
  border-right: 1px solid #59656e;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}



.navtabs a span {
  display: block;
  text-align: center;
}

.navtabs li.selected a {
  background: white;
  color: #333;
}

.navtabs,
.navtabs li,
.navtabs ul {
  height: 50px;
}

.centered {
  text-align: center;
}

.navtabs li.omega a {
  border-right: 0px;
}


/******* MAIN *******/

body #content {
  width: 100%;
  margin-top: 20px;
  min-height: 400px;
  color: #676767;
  -webkit-font-smoothing: antialiased;
}


#content p {
  margin-bottom: 24px;
  max-width: 1080px;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.625;
  font-size: 16px;
}

#content p a {
  outline: 0;
  text-decoration: none;
  color: #0081D8;
}

#content .one_col {
  padding-top: 12px;
  padding-bottom: 30px;
}

#content p,
#content h1,
#content h2,
#content h3,
.chart-option-label {
  font-family: "Open Sans","Lucida Sans Unicode", "Lucida Grande", Arial, Verdana, Tahoma, sans-serif;

  padding-left: 16px;
  padding-right: 16px;
}

#content h1,
#content h2,
#content h3 {
  text-align: center;
}

#content h1,
#content h2,
#content h3 {
  font-size: 24px;
  font-weight: normal;
  color: #333;
}

.daily-files {
  font: "Open Sans";
}
.table {
  padding: .7rem!important;
}

/******* FOOTER *******/

.footer {
  background: #46545d;
  padding: 20px 0px;
}


body .footer ul {
  display: block;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  list-style-type: none;
  padding: 0px;
  height: 30px;
}

.footer ul li {
  display: block;
  float: left;
  width: 50%;
  height: 90%;
  text-align: center;
  font-size: 10px;
  color: #f6f6f6;
}

.footer a {
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  color: #f6f6f6;
}

.footer hr {
  display: block;
  height: 1px;
  border: 0px;
  margin: 0px;
  margin-bottom: 30px;
  border-top: 1px solid #4e5f69;
}

.footer p {
  font-size: 10px;
  text-align: center;
  text-transform: none;
  color: #d1e0eb;
  line-height: 1.4;
}


/******* RESPONSIVE *******/


@media screen and (max-width: 1128px) {
  body .graph_content .one_col {
    overflow: scroll;
   }
   .col-12 {
    width: 100%!important;
  }
}


@media screen and (max-width: 1024px) {
  .hero .hero_image {
    background-image: url("./img/hero_root-servers-tablet-h.jpg");
    height: 250px;
    padding-top: 90px;
  }

  .hero_overlay h1 {
    font-size: 36px;
  }


}


@media screen and (max-width: 768px) {

  .hero .hero_image {
    background-image: url("./img/hero_root-servers-tablet-v.jpg");
    height: 220px;
    padding-top: 70px;
  }

  .hero_overlay h1 {
    font-size: 30px;
  }


  .navtabs a {
    font-size: 12px
  }

}

@media screen and (max-width: 664px) {
  .navtabs a.two_lines {
    padding-top: 8px;
  }
}

@media screen and (max-width: 500px) {
  .navtabs a {
    font-size: 11px;
    padding-top: 9px;
  }
}

.table>:not(:last-child)>:last-child>* {
  color: #ffffff;
  background-color: #46545d;
  background-image: linear-gradient(180deg, #46545d, #3a464d);
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
    padding-top: 50px;
  }

  .table>:not(caption)>*>* {
    padding: .8rem;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border: none;
  }
}