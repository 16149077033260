/*copied from namestudio.com*/
footer {
    width: 100%;
    flex: 1 0 auto;
    align-self: flex-end;
    background: #46545d;
    text-align: center;
    font-size: 0.725em;
    line-height: 1.4em;
    color: #f6f6f6;
}

.footer-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    border-bottom: 1px solid #4e5f69;
    justify-content: space-around;
}

.footer-link-container {
    display: flex;
    flex: 1 1 auto;
    max-width: 480px;
    justify-content: space-between;

    a {
        flex: 0 1 25%;
        font-size: 0.9em;
        line-height: 4.44445em;
        outline: none;
        text-decoration: none;
        color: inherit;

        &:hover {
            color: #d1e0eb;
        }
    }
}

.footer-copyright {
    padding: 30px 0;
    color: #d1e0eb;

    p {
        width: 80%;
        color: inherit;
        margin: 0 auto 0.4em;
        display: block;
    }
}